/*######################################################
  banner video
#######################################################*/
.banner-video {
	display: table;
	width: 100%;

	&-content {
		display: table-cell;
		vertical-align: middle;
		position: relative;
		height: 600px;
		background-repeat: no-repeat;
		background-position: center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

		.container-fluid {
			position: relative;
			z-index: 5;
		}

		&:before {
			opacity: .1;
			position: absolute;
			left: 0;
			top: 0;
			background-color: $green;
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			z-index: 0;
		}
	}
}