/*######################################################
  header
#######################################################*/
header#header {
	padding-top: 20px;
	padding-bottom: 12px;

	@include media-breakpoint-down(sm) {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.single &,
	.archive &,
	.page-template-template-lightpage & {
		//box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
		border-bottom: 1px solid $border-color;
	}
}