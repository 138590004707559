/*######################################################
	contact page
#######################################################*/
.pg-contactLocation {
	> .container-fluid {
		padding-bottom: 0;
		padding-top: 0;
	}

	.card {
		@include border-radius(0);
		border: none;

		@include media-breakpoint-up(lg) {
			padding-top: 50px;
			padding-bottom: 50px;

		}

		@include media-breakpoint-down(md) {
			padding-top: 10px;
			padding-bottom: 20px;
		}

		~ .card {
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
			}
		}
	}
}

.contact {
	&-location-wrap {
		position: inherit;
	}

	&-form {
		@include media-breakpoint-up(md) {
		  	max-width: 500px;
		}

		@include media-breakpoint-down(md) {
			margin: auto;
		}

	  .icon-mandatory {
	    color: $red;
	    font-size: 10px;
	    position: relative;
	    top: -5px;

	  }

	  .control-label {    
	    font-size: .875rem;
	    width: 100%;
	  }

	  .form-control {
	    &:focus {
	    	~ .label {
	    		@include transform(skewY(5deg));
	    	}
	    }

	  	@include media-breakpoint-up (sm) {
		    margin-top: 10px;
		}
	  }

	  .your-message textarea {
	  	@include media-breakpoint-up(md) {
	  		min-height: 365px;
	  	}
	  }
	}

	/* info */
	&-info {

		&-inner {
			//max-width: 500px;
		}

		&-content {
			padding: 1rem .5rem;

			@include media-breakpoint-up(sm) {
				height: 100% !important;
				border: 1px solid $border-color;
			}
		}

		&-block {

			@include media-breakpoint-up(sm) {
				flex: 0 0 33.33333%;
				max-width: 33.33333%;
				padding-left: 15px;
				padding-right: 15px;
			}

			@include media-breakpoint-only(xs) {
				flex: 0 0 100%;
				max-width: 100%;

			}

			&:not(:last-child) {
				@include media-breakpoint-up(lg) {
					border-right: 1px solid #e7eaf3;
				}

				@include media-breakpoint-only(xs) {
					border-bottom:  1px solid #e7eaf3;
					padding-bottom: 20px;
				}
			}
		}

		&-icon {
			position: relative;
			padding: 20px;

			&-main {
				color: $green;
				font-size: 25px;
				position: absolute;
				left: calc(50% - 8px);
				top: calc(50% - 14px);
			}

			&-frame {
				font-size: 60px;
				color: #E6F6F2;
			}
		}

		&-title {
			font-weight: 400;
			padding-left: 10px;
			padding-right: 10px;
			line-height: 1.2em;
			margin: auto;
			max-width: 300px;
		}

		&-detail {
			font-size: .825rem;
			font-weight: 300;
			color: #77838f;
			padding-top: 8px;
			padding-left: 10px;
			padding-right: 10px;
			line-height: 1.2em;
			margin: auto;
			max-width: 300px;
		}
	}


	/* location */
	&-location {	
		@include media-breakpoint-up(lg) {
			background-color: #F7F7F7;	
		    position: absolute;
		    left: 0;
		    top: 0;
		    height: 100%;
		    width: 50%;
		}
	}

	&-map {
		height: 100%;
		min-height: 300px;
	}
}