/*######################################################
	checkbox
#######################################################*/
.wpcf7-checkbox .wpcf7-list-item,
.checkbox label,
.alo_easymail_disclaimer label {
	display: inline-block;
    padding-left: 26px;
    position: relative;
    
    &::before {
		@include border-radius(2px);
		@include extend-icomoon;
		@extend .icon-tick:before;
		color: $white; 
		background-color: $green; 
		border-color: $green; 
		display: block;
		height: 20px;
		position: absolute;
		left: 0;
		line-height: 21px;
		text-align: center;
		top: 3px;
		width: 20px;
    }

	> input[type=checkbox] {
		@include border-radius(2px);
		@include box-shadow(inset 2px 2px 2px 0 rgba(0,0,0,0.2));
		background-color: #fff;
		border: 1px solid #2a2a2a;
		height: 20px;
		width: 20px;
		outline: none;

		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;

	    position: absolute;
	    left: 0;
	    top: 3px;	

	    &::-ms-check {
			border: 1px solid $border-color;
		    padding: 3px;
		    height: auto;
		    width: auto;
		}				

		&:checked {
			@include box-shadow(none);
			background-color: transparent;
			border-color: transparent;

			&::-ms-check {	
				color: $green; 
				background-color: $yellow; 
				border-color: $yellow; 
			}
		}
	}

	&::before {
		@include border-radius(2px);
		width: 18px;
		height: 18px;
		font-size: 12px;
	}

	> input[type=checkbox] {
		@include border-radius(2px);
		box-shadow: none;
		width: 18px;
		height: 18px;
	}
}
