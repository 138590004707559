/*######################################################
  news letter
#######################################################*/
.news-letter-wrap {

}

.news-letter-col {
	@include media-breakpoint-up(md) {
		width: 50%;

		~ .news-letter-col {
			padding-left: 40px;
		}
	}
}

.news-letter {
	@include clearfix;
	@include border-radius(6.1875rem);
	padding: 2px 2px 2px 4px;
	max-width: 400px;

	input[type="text"] {
		@include border-radius(6.1875rem);
		border: none;
		background-color: #f4f4f7;
		float: left;
		padding: 8px 20px;
		margin-top: 2px;
		width: calc(100% - 185px);
	}

	@include media-breakpoint-down(sm) {
		margin: auto;
	}
}