/*######################################################
  form
#######################################################*/
.form {
    &-control {	
        @include border-radius(0);
        border: 1px solid $border-color;
        background-color: $white;
        padding: 10px 15px;
        height: inherit;

        &:focus {
        	background-color: $gray-100;    	
        }

        &.wpcf7-not-valid  {
        	border-color: $red;
        }
    }

    &-group {
        position: relative;

        @include media-breakpoint-down(sm) {
            margin-bottom: .4rem;
        }

        
    }
}

.control-label {
    font-size: .875rem;
    font-weight: 500;
    width: 100%;
}

.label {
    
}