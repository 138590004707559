/*######################################################
  text typing
#######################################################*/
.text-typing {
	&:after {
		@extend .animate-flicker;
		content: '|';
		display: inline-block;
		padding-left: 2px;
	}
}
