/*######################################################
  promo
#######################################################*/
.ps-promo {
	/*
	background-repeat: no-repeat;
	background-position: center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position: relative;

	&:before {
		content: '';
		display: block;
		background-image: linear-gradient(150deg, rgba(4, 145, 111, 0.9) 0%, rgba(0, 165, 125, 0.85) 100%);
		background-repeat: repeat-x;
		z-index: 0;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}

	> .container,
	> .container-fluid {
		position: relative;
		z-index: 2;
	}
	*/
	position: relative;

	&:before {
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    content: "";
	    //background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'><circle cx='50' cy='50' r='15' fill='%23f5ba4b'></circle></svg>");
	    //background-size: .5rem;    
	    background-image: url('img/banner/456.png');
	    background-repeat: no-repeat; 
	    background-position: center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	    opacity: .2;
    	z-index: 0;
	}

	> .container,
	> .container-fluid {
		position: relative;
		z-index: 3;
	}
}