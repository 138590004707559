/*######################################################
  navbar
#######################################################*/
.navbar {
  &-nav {
    padding-top: 40px;

    @include media-breakpoint-down(sm) {
      padding-left: 18px;
      padding-right: 18px;
    }

    a {
      @include transition(all linear .3s);
      color: $black;
      font-weight: 400;

      &:active,
      &:focus {
        color: $black;
      }

      &:hover {
        color: $red;
      }
    }

    .current-menu-item,
    .current_page_item {
      a {
        color: $red;
      }
    }
  }

  .container-fluid {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }

  .menu-item,
  .nav-item {
    @include media-breakpoint-up(md) {
      padding-left: 1em;
      &:not(:last-child) {
        padding-right: 1em;
      }
    }

    > a {
      @extend .nav-link;
      font-size: 1.125rem;
    }
  }

  &-brand {
    background: transparent url('img/ksu-logo.png') 0 0 no-repeat;
    background-size: 100% auto;
    display: inline-block;
    height: 70px;
    width: 200px;
    text-indent: -999em;
    overflow: hidden;
    outline: none;

     @include media-breakpoint-between(md, md) {
      width: 170px;
      height: 60px;
    }

    @include media-breakpoint-down(sm) {
      width: 140px;
      height: 50px;
    }
	 
   /*
    background: transparent url(img/ksu-logo.png) 0 12px no-repeat;
    background-size: auto 42px;
    display: inline-block;
    height: 70px;
    width: 300px;
    overflow: hidden;
    outline: none;
    padding-left: 56px;
    color: #161616;
    font-size: 2.2rem;
    font-weight: 600;
    padding-top: 6px;
    */
	
	&:hover,
	&:focus,
	&:active {
		color: #161616;
	}
  }

  &-top {
    margin-left: auto !important;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: .2em .4em;
    }

    a {
      @include transition(all linear .4s);
      @include border-radius(2px);
      color: $green;
      border: 1px solid $green;
      padding: 4px;

      &:hover,
      &:focus,
      &:active {
        background-color: $green;
        color: $white;
        text-decoration: none;
      }
    }
  }
}


.navbar-toggler {
  @include transform (rotate(0deg));
  @include transition(.5s ease-in-out);
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  right: 0;
  top: 4px;
  position: absolute;
  width: 40px;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }


  span {
    @include transform (rotate(0deg));
    @include transition(.25s ease-in-out);
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $red;
    opacity: 1;
    left: 0;
    
    &:nth-child(1) {
      top: 8px;
    }

    &:nth-child(2), 
    &:nth-child(3) {
      top: 18px;
    }

    &:nth-child(4) {
      top: 28px;
    }

  }


  &.on {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        @include transform (rotate(45deg));
      }

      &:nth-child(3) {
        @include transform (rotate(-45deg));
      }

      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

    }
  }
}