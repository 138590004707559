/*######################################################
  testimonials
#######################################################*/
.testimonials {
	
	&-item {
		padding-left: .9375rem;
		padding-right: .9375rem;
		opacity: 0;
		transition: opacity .3s liniear;

		.slick-initialized & {
			opacity: 1;
		}

		~ .testimonials-item {
			display: none;
		}
	}

	&-author {
		background-color: #fff;
		position: relative;
		padding-bottom: 2rem;
		//margin-top: -30px;

		p.small {
			color: $yellow;
		}
	}

	&-avatar {
		width: 3.125rem;
		height: 3.125rem;
		margin-bottom: 1em;
	}

	.card-body {
		padding: 2rem 3rem 3rem 3rem;
		position: relative;
	    font-size: 1.15rem;
	    line-height: 1.6em;
		p {
			line-height: inherit;
		}

		&:before,
		&:after {
			@include extend-icomoon;
			font-size: 90px;
			position: relative;
			opacity: .1;
			z-index: 0;
		}

		&:before {
			//@extend .icon-quotes-left:before;
		}

		/*
		&:after {
			@extend .icon-quotes-right:before;
		}
		*/
	}

	.slick-dots li button {
		@include border-radius(0);
		cursor: pointer;
		height: 45px;
		width: 45px;
		border: none;	
		background-color: transparent;	
		position: relative;
		text-indent: -999rem;

		&:before {
			background-color: $white;
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -2.5px;
			width: 100%;
			height: 5px;
		}
	}

	.slick-dots li.slick-active button {
		&:before {
			background-color: $yellow;
		}
	}
}

.ps-testimonials {
	background-repeat: no-repeat;
	background-position: center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position: relative;

	&:before {
		content: '';
		display: block;
		background-image: linear-gradient(150deg, rgba(4, 145, 111, 0.9) 0%, rgba(0, 165, 125, 0.85) 100%);
		background-repeat: repeat-x;
		z-index: 0;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}

	> .container,
	> .container-fluid {
		position: relative;
		z-index: 2;
	}

	.page-section-title {
		color: #fff;
	}

	.page-section-subtitle {
		color: wheat;
	}
}