/*######################################################
  card
#######################################################*/
.card {
	border-color: #e7eaf3;
	
	&-body {
		p {
			color: #77838f;
			font-weight: 300;	
			line-height: 1.8em;
		}
	}

	&-title {		
	    padding-top: 6px;
	    padding-bottom: 10px;
	    line-height: 1.4em;

		a {
			color: #2a2a2a;

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}

			&:hover {
				color: $green;
			}
		}
	}

	&-text {
		font-size: 0.875rem;
	}
}