/*######################################################
	fonts
#######################################################*/
@font-face {
  font-family: 'ksu';
  src:  url('fonts/ksu/fonts/ksu.eot?8mvjuc');
  src:  url('fonts/ksu/fonts/ksu.eot?8mvjuc#iefix') format('embedded-opentype'),
    url('fonts/ksu/fonts/ksu.ttf?8mvjuc') format('truetype'),
    url('fonts/ksu/fonts/ksu.woff?8mvjuc') format('woff'),
    url('fonts/ksu/fonts/ksu.svg?8mvjuc#ksu') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ksu' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-management-system:before {
  content: "\e900";
}
.icon-award-winning:before {
  content: "\e901";
}
.icon-stakeholder-commitment:before {
  content: "\e902";
}
.icon-consumer-expactations:before {
  content: "\e903";
}
.icon-double-arrow-left:before {
  content: "\e906";
}
.icon-double-arrow-right:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e90c";
}
.icon-chevron-left:before {
  content: "\e90f";
}
.icon-chevron-right:before {
  content: "\e910";
}
.icon-tick:before {
  content: "\e935";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-check:before {
  content: "\f17b";
}