/*######################################################
  portfolio
#######################################################*/
.portfolio {
	&-list {
		padding-top: 20px;
	}
	
	&-item {
		border: none;

		.portfolio-list & {
			margin-bottom: 1.4rem;
		}

		.card-title {	
			font-weight: 500;		
			color: $white;
			margin: 0;
			padding: 0 0 10px 0;
			line-height: 1.2em;
		}
	}

	&-inner {
		background-color: transparent;
		background-image: linear-gradient(to right, $red , lighten($red, 10%));
		//background-image: linear-gradient(159.58deg, $red 0%, lighten($red, 10%) 100%);
		box-shadow : 0 0.75rem 1.25rem 0.0625rem rgba(64,64,64,0.09);
		height: 100%;
	}

	&-image {
		display: block;
		position: relative;
		overflow: hidden;

		&:before {
			@include transition(all .2s ease-in-out); 
			@include border-radius(50%);
			@include extend-icomoon;
			@extend .icon-link:before;
			background-color: $white;
			color: $red;
			display: block;
			font-size: 2rem;
			opacity: 0;
			position: absolute;
			height: 60px;
			width: 60px;
			text-align: center;
			line-height: 58px;
			left: 50%;
			top: 50%;
			margin-left: -30px;
			margin-top: -30px;
    		transform: translateY(-3rem);
			z-index: 2;
		}

		&:after {
			@include transition(all .2s ease-in-out); 
			content: '';
			background-color: rgba($red, .6);
			display: block;
			height: 100%;
			position: absolute;
			opacity: 0;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 1;
		}
			
		img {
			@include transition(all .2s ease-in-out); 
			position: relative;
			z-index: 0;
		}
	}

	&-link {
		height: 100%!important;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;

			.card-title {
				color: $white;
			}
		}


		&:hover {
			.portfolio-image {
				&:before {
					opacity: 1;
					transform: translateY(0);
				}
				&:after {
					opacity: 1;
				}
				img {
					transform: scale(1.1);
				}
			}
		}
	}
}