/*######################################################
	section
#######################################################*/
.page-section {
	position: relative;	

	&-title {
		color: $black;
		font-size: 2.5rem;
		line-height: 1.2em;
		font-weight: bold;
		margin-bottom: 2.4rem;
		margin-left: auto;
		margin-right: auto;
		max-width: 600px;
		letter-spacing: 1px;


	    
	    font-size: 2.25rem;
	    font-weight: 300;
	    line-height: 1.167;

		@include media-breakpoint-down (sm) {
			font-size: 1.9rem;
			line-height: 1.2em;
		}

		span {
			//font-weight: 600;
		}
	}

	&-subtitle {
		font-weight: 400;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		margin-top: -20px;
		margin-bottom: 40px;
		max-width: 900px;
		line-height: 2em;
	}

	> .container-fluid,
	> .container {
		padding-top: 60px;
		padding-bottom: 60px;

		@include media-breakpoint-down(sm) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		@include media-breakpoint-down(xs) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	&.pg-full-width > {
		.container-fluid,
		.container {
			max-width: 100%;
		}
	}
}

.section-divider {
	@include media-breakpoint-up(xl) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    
    @include media-breakpoint-between(md, lg) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @include media-breakpoint-between(sm, sm) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
}