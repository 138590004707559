@import "vendor/wp-header";

@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/animation";

@import "frameworks/customBootstrap";

//@import "vendor/bootstrap-datepicker";
//@import "vendor/bootstrap-select";
@import "vendor/slick-carousel";
@import "vendor/dzsparallaxer";
@import "vendor/icomoon";
//@import "vendor/swipebox"; //popup slider
@import "vendor/magnific-popup"; // secondary popup slider

@import "base/color";
@import "base/fonts";
@import "base/heading";
@import "base/paragraph";
@import "base/reset";
@import "base/typography";



@import "components/buttons";
@import "components/card";
@import "components/checkbox";
@import "components/form";
@import "components/pagination";
@import "components/navbar";
@import "components/section";

/*
@import "components/dropdown";
@import "components/select";
@import "components/accordion";
@import "components/list-group";
@import "components/modal";
@import "components/navigation";
@import "components/radio";
@import "components/table";
*/


/*
@import "widgets/home-carousel";
@import "widgets/testimonials";
@import "widgets/social-menu";
@import "widgets/work-grid";
@import "widgets/people-grid";
*/


/*
@import "widgets/banner";
@import "widgets/contact";
@import "widgets/hero";
@import "widgets/listing";
@import "widgets/opening-special";
@import "widgets/reservation";
@import "widgets/ribbon";
@import "widgets/tile";


@import "widgets/category-thumbnail-tile";
@import "widgets/getting-started-tile";
@import "widgets/region-languages";
@import "widgets/social-media";
@import "widgets/submit-and-register";
@import "widgets/timeline";
*/


@import "widgets/banner";
@import "widgets/banner-carousel";
@import "widgets/banner-subpage";
@import "widgets/banner-video";
@import "widgets/blog";
@import "widgets/experiences";
@import "widgets/image-popup-slider";
@import "widgets/management";
@import "widgets/news-letter";
@import "widgets/partners";
@import "widgets/portfolio";
@import "widgets/promo";
@import "widgets/services";
@import "widgets/testimonials";
@import "widgets/text-typing";
@import "widgets/visi";
@import "widgets/work-carousel";

@import "layout/content";
@import "layout/footer";
@import "layout/grid";
@import "layout/header";


@import "pages/about";
@import "pages/contact";




/* order is important */
