/*######################################################
	buttons
#######################################################*/
.btn {
	border: none !important;
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;

	[class*="icon"],
	[class^="icon"] {
		background-color: rgba(0, 0, 0, 0.1);
		position: absolute;
		left: 0;
		top: 0;
		line-height: 45px;
		text-align: center;
		width: 45px;
		height: 45px;
	}

	&.btn-icon {
		padding-left: 55px;
	}
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}


/* Pill */
.btn-pill {
  border-radius: 6.1875rem;

  [class*="icon"],
  [class^="icon"] {
  	@include border-radius(50%);
  }
}
