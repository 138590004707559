/*######################################################
  work carousel
#######################################################*/
.work-carousel {
	&-item {
		img {
			height: auto;
			max-width: 100%;
			width: 100%;
		}

		~ .work-carousel-item {
			display: none;
		}

		&:hover {
			.work-carousel-image {
				@include transform(translate(0, -100px ));

				img {
					opacity: .8;
				}
			}
		}
	}

	&-wrap{
		margin-bottom: 30px;

		&:not(.slick-initialized) .work-carousel-item { 
			@include media-breakpoint-up(md) {
				max-width: 25%;
			}

			@include media-breakpoint-between(sm,md) {
				max-width: 33.33333333333333%;
			}
		}
	}

	&-arrow {
		@include transition(all linear .4s);
		background-color: rgba(255, 193, 7, .4); //rgba(0, 165, 125, .4);
		border: none;
		color: #fff;
		cursor: pointer;
		font-size: 30px;
		position: absolute;
		top: 0;
		height: calc( 100% - 7px);
		width: 80px;
		z-index: 2;

		&--prev {
			left: 0;
		}

		&--next {
			right: 0;
		}

		&:active,
		&:focus,
		&:hover {
			outline: none !important;
		}

		&:hover {
			background-color:  rgba(255, 193, 7, .8); //rgba(0, 165, 125, .8);
		}
	}

	&-link {
		display: block;
		outline: none;		

		&:active,
		&:focus,
		&:hover {
			text-decoration: none;
			outline: none !important;
		}

	}

	&-inner {
		background-color: $green;
		position: relative;
	}

	&-image {
		@include transition(all linear .3s);
		height: 360px;
		overflow: hidden;
		position: relative;
		z-index: 3;

		img {
			@include transition(all linear .3s);
			opacity: 1;
		}

	}

	&-caption {
		color: $white;
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 10px 20px;
		line-height: 1em;
		text-align: right;
		//text-shadow: 0px 0px 8px rgba(0, 0, 0, .8);
		width: 100%;
		z-index: 0;
	}
}