/*######################################################
  blog
#######################################################*/
.blog {
	&-list {
		@include media-breakpoint-up(md) {
			column-count: 2;
		}

		.card {
			margin-bottom: 1.25rem;

			&-text {				
		    color: $gray-600;
		    font-weight: 300;
		    line-height: 1.8em;
			}
		}
	}

	&-info {
		.text-muted:not(:last-child) {
			margin-right: 10px;
		}

		[class*="icon"] {
			margin-right: 6px;
		}

		.icon-comments {			
		    font-size: 18px;
		    position: relative;
		    top: 2px;
		}
	}

	&-category-list {
		/*
		font-size: .9rem;
		font-weight: 400;
		margin-bottom: 0;

		li {
			display: block;
			padding-right: 30px;
			position: relative;
			
			&::before {	
				@include extend-icomoon;
				@extend .icon-chevron-right:before;	
				color: $gray-600;
				display: block;
				font-size: 10px;
				position: absolute;
				left: 0;
				top: 16px;
			}



			a {
				@include transition(all linear .3s);
				color: $green;
				display: inline-block;
				padding: 10px 0 10px 20px;

				&:hover,
				&:active,
				&:focus {
					color: $green;
					text-decoration: none;
				}

				&:hover {
					@include transform(translate(.5rem, 0, ));
				}
			}

			> span {
				color: #77838f;
				float: right;
				position: absolute;
				right: 0;
				text-align: center;
				top: 8px;
				min-width: 30px;
			}
		}
		*/
	}
}