/*######################################################
	content
#######################################################*/
.pg-scew {
  position: relative;
  top: -40px;
}

body.single {
  @include media-breakpoint-up(lg) {
    .page-title {
      max-width: 66.66667%;
    }

    .image-popup-slider {
      margin-top: -52px;
    }

    .sticky-top > .widget-area:first-child {
      margin-top: -30px;
    }
  }
}

.post-image {
  img {
    width: 100%;
  }
}

.col-lg-4.page-column-right {
  @include media-breakpoint-up(lg) {
    padding-left: 50px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 30px;
  }
}

.pg-bg-teal {
  background-color: $teal;
  //background-image: linear-gradient(to right, $blue , lighten($blue, 10%));
  color: $white;

  &.bg-img-full-text {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-attachment: fixed;
    background-image: url(img/banner/bg-refinery.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    &:before {
      background-color: rgba($teal, .3);
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: 0 100%;
      background-size: 100% auto;
      z-index: 0;
    }

    > .container-fluid,
    > .container {
      position: relative;
      z-index: 4;
    }

    @include media-breakpoint-between(sm,sm) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    @include media-breakpoint-only(xs) {
      padding-top: 3rem;
      padding-bottom: 3rem;

      .wysiwyg { 
        font-weight: 300;
        line-height: 1.7em;
      }
    }
  }

  .page-section-title,
  .wysiwyg {
    color: $white;
  }

  .wysiwyg {
    font-size: 1.125rem;     
    font-weight: 400;
    line-height: 2.2em;
  }

  + .page-section {
    > .container,
    > .container-fluid {
      padding-top: 0;
    }
  }
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.page-title {  
    font-weight: 300;
    padding-bottom: 30px;

    font-size: 2.2rem;
    line-height: 1.25;
}

.side-by-side {
  position: relative;

  @include media-breakpoint-down(sm) {
    top: 0;

    .d-flex {
      display: block;
    }

    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .container-fluid {
    padding-top: inherit;
    padding-bottom: inherit;
  }
  
  .col-image {
    position: inherit;
    @include media-breakpoint-down(sm) {
      padding-top: 70px;
    }

    .frame-image {
        position: relative;
        background-repeat: no-repeat;
        background-position: right top; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

      @include media-breakpoint-up(md) {
        position: absolute;
        right: 0;
        width: 50%;
        height: 100%;
      }

      @include media-breakpoint-down(sm) {
        min-height: 300px;
      }



      &:before {
        background-color: rgba($blue, .3);
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: 100% auto;
        z-index: 0;
      }
    }
  }
  .col-content {
    @include media-breakpoint-up (md) {
      padding-top: 250px;
      padding-bottom: 150px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      padding-top: 20px;
      padding-bottom: 50px;

      h2 {
        margin-bottom: 10px;
      }
    }
  }
}

.navbar {
  .btn.btn-secondary {
    @include media-breakpoint-up (md) {
      padding-top: 8px;
      padding-bottom: 8px;
      position: absolute;
      top: -12px;
      right: 0;
    }

    @include media-breakpoint-down (sm) {
      margin-top: 20px;
    }
  }
}

.widget_search {

}

.search-form {
  position: relative;
  padding-right: 115px;
  label {
    margin-bottom: 0;
    width: 100%;

    span {
      font-weight: 400;
      display: block;
      padding-bottom: 6px;
    }
  }

  .search-field {
    @extend .form-control;
    width: 100%;
  }
}

.search-submit {
  @extend .btn;
  @extend .btn-secondary;
  bottom: 0;
  position: absolute;
  right: 0;
  width: 113px;
}

.sidebar .widget ~ .widget {
  padding-top: 30px;
}

.widget-post-list {
  .media {
    &:not(:first-child) {
      padding-top: 15px;
      border-top: 1px solid $border-color;
    }
    padding-bottom: 15px;
  }
  .media-label {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5em;
  }

  a {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}

.widget-title {
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 300;
  margin: 0 0 15px 0;
  padding-bottom: 10px;
  position: relative;
  border-bottom: 2px solid $border-color;

  &:after {
    background-color: $red;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 40%;
  }
}

.widget-post-image {
  max-width: 64px;
  margin-bottom: 0;

  img {
    @extend .img-fluid;
  }
}
