/*######################################################
  banner subpage
#######################################################*/
.banner-subpage {
	&-image {
		display: table;
		position: relative;
		width: 100%;

		background-repeat: no-repeat;
		background-position: center center; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

		&:before {
			background-color: rgba($blue, .3);
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}
	}

	&-inner {
		display: table-cell;
		position: relative;
		vertical-align: middle;
		width: 100%;    
		padding-bottom: 6rem;
	    padding-top: 4rem;

	    @include media-breakpoint-down(sm) {
	    	padding-bottom: 2rem;
	    	padding-top: 1rem;
	    }
	}



	&-title {
		font-size: 4.5rem;
		font-weight: 400;
		line-height: 1.1em;
		margin: 0;
		padding-top: 40px;
		padding-bottom: 40px;
		text-shadow: 1px 0 10px rgba(0, 0, 0, 0.3);			

		@include media-breakpoint-between(sm,sm) {
			font-size: 3.2rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2.5rem;			
		}

		.is-title-large & {		
			@include media-breakpoint-up(lg) {
				font-size: 6rem;
			}
		}

		~ .banner-subpage-subtitle {
			position: relative;
			margin-top: -30px;
		}
	}

	.container-fluid {
		position: relative;
		z-index: 5;
	}
}