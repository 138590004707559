.ps-visi {
	background-image: url("img/banner/bg-pipes.jpg");
	background-repeat: no-repeat;
	background-position: center center;
    background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	position: relative;

	&:before {
		background-color: rgba($red, .85);
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: 0 100%;
		background-size: 100% auto;
		z-index: 0;
	}

	.container-fluid {
		position: relative;
		z-index: 3;
	}

	.page-section-title {
		color: $white;
	}
}

.visi-item {
	.card {
		@include border-radius(0);
		background-color: transparent;
		border: none;
		text-align: center;
	}

	.card-body {
		font-weight: 300;
		line-height: 2rem;
	}
}