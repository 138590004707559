/*######################################################
  management
#######################################################*/
.ps-management {
	background-image: url("img/banner/bg-pipes.jpg");
	background-repeat: no-repeat;
	background-position: center center;
    background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: $white;
	position: relative;

	&:before {
		background-color: rgba($red, .85);
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: 0 100%;
		background-size: 100% auto;
		z-index: 0;
	}

	.container-fluid {
		position: relative;
		z-index: 3;
	}

	.page-section-title {
		color: $white;
	}
}

.management {

	&-icon {
	} 

	&-item {
		padding-bottom: 30px;

		> a {
			display: block;
			height: 100%;

			&:active,
			&:hover,
			&:focus {
				text-decoration: none;
			}

			&:hover {
				cursor: default;
			}
		}
	}

	&-content {
		font-weight: 300;
		//font-size: .825rem;
		//line-height: 24px;
	}

	&-icon {
		position: relative;

		&-main {
			font-size: 60px;
		}
	}



	a {
		color: $white;
	}

	/* cards */

	.card {
		@include border-radius(0);
		background-color: transparent;
		border: none;
		

		&-image {
			text-align: center;
			padding: 1.5em 1em 1em 1em;

			.management-icon {				
				display: block;

				@include media-breakpoint-up(lg) {
					max-width: 200px;
				}
			}

			.management-icon-main {
				color: $white;
				font-size: 7.5rem;
			}
		}

		&-title {
			@include transition(all 0.4s linear);
			padding: 0;
			text-transform: uppercase;
		}

		&-body {
		}

		&-footer {
			display: none;
			background: transparent;
		}
	}
}