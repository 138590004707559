.image-popup-slider {
	padding-bottom: 30px;
}

.post-image-wrap {
	display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
    	margin-left: -5px;
    	margin-right: -5px;
    }
}

.post-image-tile {
	max-width: 50%;
	padding-bottom: 10px;

	@include media-breakpoint-up(lg) {
		&:nth-child(2n+1) {
			padding-right: 5px;
		}

		&:nth-child(2n+2) {
			padding-left: 5px;
		}
	}

	@include media-breakpoint-down(md) {
		padding-left: 5px;
		padding-right: 5px;
	}

	@include media-breakpoint-down(xs) {
		width: 100%;

		img {
			width: 100%;
		}
	}

	img {
		@extend .img-fluid;
	}
}