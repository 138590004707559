/*######################################################
  banner
#######################################################*/
.banner {
	padding-bottom: 50px;

	img {
		height: auto;
		max-width: 100%;
		width: 100%;
	}

	&-image {
		background-repeat: no-repeat;
		background-position: center center; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

		min-height: 300px;
		display: table;
		width: 100%;
	}

	&-inner {
		position: relative;
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		/*
		&:before {
			opacity: .15;
			position: absolute;
			left: 0;
			top: 0;
			background-color: $green;
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			z-index: 0;
		}
		*/

		#SVGMainHero {
			position: absolute;
			height: 100%;
			top: 0;
			right: 0;

			image {
				max-height: 100%;
			}
		}
	}

	&-content {
		color: #2a2a2a;
		width: 50%;
	}

	&-title {
		font-size: 2.5rem;
		line-height: 1.1em;
		margin: 0;
		padding-top: 40px;
		padding-bottom: 40px;
		position: relative;
		z-index: 5;
	}
}