/*######################################################
  services
#######################################################*/
.services {
	&-list {

	}

	&-item {
		padding-top: 20px;
		padding-bottom: 25px;

		&:not(:first-child) {
			border-top: 1px solid $border-color;
		}
	}

	&-title {
		font-size: 1.2rem;
		font-weight: 400;
		line-height: 1.3em;
		padding-bottom: 10px;
		margin: 0;
	}

	&-image {
		@include media-breakpoint-up(md) {
			max-width: 400px;
			padding-right: 40px;
		}

		@include media-breakpoint-down(sm) {
			padding-bottom: 20px;

			img {
				margin: auto;
				max-width: 100%;
			}
		}

		img {
			@extend .img-fluid;
		}
	}

	&-link {
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}

		.media {
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}


	/*=============== home services =============*/
	&-home {
		&-list {
			margin-top: -15px;
			margin-bottom: -15px;
		}

		&-item {
			margin-bottom: 15px;
			margin-top: 15px;
			border: none;

			.portfolio-list & {
				margin-bottom: 1.4rem;
			}

			.card-title {	
				font-weight: 300;	
				font-style: normal;	
				color: $white;
				margin: 0;
				padding: 0;
				line-height: 1.2em;
			}
		}

		&-inner {
			background-color: $red;
			//background-image: linear-gradient(to right, $red , lighten($red, 10%));
			box-shadow : 0 0.75rem 1.25rem 0.0625rem rgba(64,64,64,0.09);
			height: 100%;
		}

		&-image {
			background-color: #dddddd;
			display: block;
			position: relative;
			overflow: hidden;
			min-height: 220px;

			&:before {
				@include transition(all .2s ease-in-out); 
				@include border-radius(50%);
				@include extend-icomoon;
				@extend .icon-link:before;
				background-color: $white;
				color: $red;
				display: block;
				font-size: 2rem;
				opacity: 0;
				position: absolute;
				height: 60px;
				width: 60px;
				text-align: center;
				line-height: 58px;
				left: 50%;
				top: 50%;
				margin-left: -30px;
				margin-top: -30px;
	    		transform: translateY(-3rem);
				z-index: 2;
			}

			&:after {
				@include transition(all .2s ease-in-out); 
				content: '';
				background-color: rgba($red, .6);
				display: block;
				height: 100%;
				position: absolute;
				opacity: 0;
				left: 0;
				top: 0;
				width: 100%;
				z-index: 1;
			}
				
			img {
				@include transition(all .2s ease-in-out); 
				position: relative;
				z-index: 0;
			}
		}

		&-link {
			height: 100%!important;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;

				.card-title {
					color: $white;
				}
			}


			&:hover {
				.services-home-image {
					&:before {
						opacity: 1;
						transform: translateY(0);
					}
					&:after {
						opacity: 1;
					}
					img {
						transform: scale(1.1);
					}
				}
			}
		}

	}
}