@mixin breaktext(){
	white-space: pre-wrap;       /* css-3 */
	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	word-wrap: break-word;  
}

@mixin ellipsis(){
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

@mixin border-radius($radius){
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
          overflow: hidden;
}

@mixin transition($transition, $transition2:false, $transition3:false ){
    @if( $transition2 or $transition3  ) {  

        @if( $transition2 and $transition3 == false  ) {  
            transition: $transition, $transition2;
            -webkit-transition: $transition, $transition2;
            -moz-transition: $transition, $transition2;
            -o-transition: $transition, $transition2;
        }

        @if( $transition2 and $transition3  ) {  
            transition: $transition, $transition2, $transition3; 
            -webkit-transition: $transition, $transition2, $transition3;
            -moz-transition: $transition, $transition2, $transition3;
            -o-transition: $transition, $transition2, $transition3;
        }

    } @else {        
        transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -o-transition: $transition;
    }
}

@mixin box-shadow ($boxshadow){
	box-shadow: $boxshadow;
	-webkit-box-shadow: $boxshadow;
	-moz-box-shadow: $boxshadow;
	-o-box-shadow: $boxshadow;
}

@mixin transform($transform) {
	-moz-transform: $transform;
	-ms-transform: $transform;
	-o-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
    -webkit-backface-visibility: hidden;
}

@mixin extend-icomoon (){
    font-family: 'ksu';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@mixin background-retina($file-1x, $file-2x, $width, $height) {
    @include img-retina($file-1x, $file-2x, $width, $height);
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin is-portrait {
    @media screen and (orientation:portrait) { 
        @content;
    }
}

@mixin is-landscape {
    @media screen and (orientation:landscape) { 
        @content;
    }
}