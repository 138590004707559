/*######################################################
	typography
#######################################################*/
.wysiwyg {
	font-size: 1rem;
	font-weight: 400;
	color:#2a2a2a;
    line-height: 2rem;

    font-weight: 300;
    //line-height: 1.5;

    h1, h2, h3, h4, h5, h6 {
        //font-family: $font-secondary;
        font-weight: 300;
        margin-bottom: 1em;
    }

    img {
    	@extend .img-fluid;
    }

    h1 {
    	@extend .h1;
    }

    h2 {
    	@extend .h2;
    }

    h3 {
    	@extend .h3;
    }

    h4 {
    	@extend .h4;
    }

    h5 {
    	@extend .h5;
    }

    h6 {
    	@extend .h6;
    }
}