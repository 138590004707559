/*######################################################
	footer
#######################################################*/
#footer {
	background-color: #111111;//$gray-900;
	color: $white;
	padding-top: 20px;

	.d-lg-flex {
		@include media-breakpoint-down(sm) {
			.row {
				//margin-left: 0;
				//margin-right: 0;
			}

			> [class*="col"],
			> [class^="col"] {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}

.footer {
	&-block {
		padding-top: 15px;
		padding-bottom: 10px;

		~ .footer-block {
			border-top: 1px solid #272727;//$gray-700;
		}
	}

	&-logo {
		img {
			max-width: 140px;
		}
	}

	&-address {
		//color: $gray-600;
		font-weight: 300;
		line-height: 1.6em;
		padding-top: 20px;
		padding-bottom: 20px;

		p {
			margin: 0;
		}
	}

	&-menu {
		font-size: 1rem;
		font-weight: 400;
		padding: 0 0 0 5px;
		margin: 0;

		li {
			list-style: none;
			padding: 0;
			position: relative;
		}

		a {
			@include transition(all linear .3s);
			color: $teal;
			display: inline-block;
			padding: 10px 20px;

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}

			&:hover {
				color: $white;
			}
		}
	}
}