/*######################################################
  experiences
#######################################################*/
.experiences {
	.media {
		 align-items: initial;
	}
	&-item {
		&.image-order-left {
			@include media-breakpoint-up(md) {
				background-color: $red;
			}
		}

		@include media-breakpoint-down(sm) {
			.media {
				display: block;
			}
		}
	}

	&-link {
		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}

	&-title {
		color: #2a2a2a;
		font-size: 1.3125rem;
		font-weight: 400;
		font-style: normal;
		line-height: 1.3em;
		padding-bottom: 15px;
		margin: 0;

		.image-order-left & {
			@include media-breakpoint-up(md) {
				color: $white;
			}
		}

		.image-order-right & {
			@include media-breakpoint-up(md) {
				text-align: right;
			}
		}
	}

	&-image {
		background-repeat: no-repeat;
		background-position: center center; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

		@include media-breakpoint-up(md) {
			width: 40%;
			max-width: 400px;
		}

		@include media-breakpoint-down(sm) {
			max-height: 300px;
			overflow: hidden;
		}

		img {
			@extend .img-fluid;

			@include media-breakpoint-up(md) {
				display: none;
			}
		}

		.image-order-left & {
			@include media-breakpoint-up(md) {
				padding-right: 20px;
			}
		}

		.image-order-right & {
			@include media-breakpoint-up(md) {
				padding-left: 20px;
			}
		}
	}

	&-body {
		@include media-breakpoint-up(md) {
			padding: 30px;
		}

		@include media-breakpoint-down(sm) {
			padding: 20px 0 40px 0;
			border-bottom: 1px solid $border-color;
			margin-bottom: 40px;
		}
	}

	&-text {
		line-height: 1.8em;

		.image-order-right & {
			@include media-breakpoint-up(md) {
				text-align: right;
			}
		}

		.image-order-left & {
			@include media-breakpoint-up(md) {
				color: $white;
			}
		}
	}
}