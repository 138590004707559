/*######################################################
	pagination
#######################################################*/
.pagination {
	display: flex;
	display: -ms-flexbox;
	-ms-flex-pack: center!important;
	justify-content: center!important;
	padding: 20px 0;

	.page-numbers {
		@include transition(all linear .2s);
		border: 1px solid $gray-300;
		background-color: $white;
		color: $black;
		display: block;
		font-size: .925rem;
		font-weight: 400;
		padding: 5px;
		line-height: 32px;
		margin: 0 4px;
		height: 42px;
		text-align: center;
		width: 42px;

		&:hover,
		&:active,
		&:focus {
			background-color: $white;
			color: $black;
			text-decoration: none;
		}

		&:hover,
		&.current,
		&.current:hover {
			color: $white;
			background-color: $red;
			border-color: $red;
		}
	}

	.prev,
	.next {
		font-size: 0;
		line-height: inherit;

		&:before {
			@include extend-icomoon;
			display: block;
			font-size: .925rem;
			line-height: 32px;
			left: 0;
			top: 0;
		}
	}

	.prev {
		&:before {
			@extend .icon-double-arrow-left:before;
		}
	}

	.next {
		&:before {
			@extend .icon-double-arrow-right:before;
		}
	}
}