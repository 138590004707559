/*######################################################
  partners
#######################################################*/
.partners {
	&-content {
		margin: auto;
		max-width: 800px;
	}

	&-item {
		max-width: 160px;
		padding-bottom: 20px;
	}
}