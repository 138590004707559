/*######################################################
	banner carousel
#######################################################*/
.banner-carousel {

	.skew.bottom-0 {
		bottom: 0;
	}

	.slick-initialized ~ .skew.bottom-0 {
		bottom: 6px;
	}

	+ .page-section > .container-fluid {
		padding-top: 0;
	}

	&-item {

		~ .banner-carousel-item {
			display: none;
		}
	}

	&-image {
		position: relative;
		background-repeat: no-repeat;
		background-position: center center; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;

		@include media-breakpoint-up(lg) {
			min-height: 700px;
		}

		&:before {
			background-color: rgba($blue, .3);
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}
	}

	&-text {
		display: table;
		color: #fff;
		position: relative;
		max-width: 500px;
		z-index: 5;
	}

	&-content {
		display: table-cell;
		padding-bottom: 8rem;
		vertical-align: middle;

		@include media-breakpoint-down(md) {
			padding-top: 2rem;
			padding-bottom: 6rem;
		}
	}

	&-description {
		@include transition(opacity linear 1s);
		opacity: 0;

		.slick-initialized & {
			opacity: 1;
		}
	}

	&-title {
		font-size: 4rem;
		font-weight: 400;
		line-height: 1.2;
		text-shadow: 1px 0 10px rgba(0, 0, 0, 0.3);

		@include media-breakpoint-between(sm,sm) {
			font-size: 3.2rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 2.5rem;			
		}
	}

	&-subtitle {
		color: $white;
		font-weight: 300;
		text-shadow: 1px 0 10px rgba(0, 0, 0, 0.3);
		margin: 0;

		@include media-breakpoint-up(md) {
			font-size: 1.125rem;
			padding-top: 30px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1rem;
			padding-top: 10px;
		}
	}
}

